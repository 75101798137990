<template>
    <div id="signup" class="kt-grid kt-grid--ver kt-grid--root">
        <div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
            <div
                class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile"
            >
                <!--begin::Aside-->
                <app-welcome />
                <!--begin::Aside-->

                <!--begin::Content-->
                <div
                    class="kt-grid__item kt-grid__item--fluid kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper"
                >
                    <!--begin::Body-->
                    <div class="kt-login__body">
                        <!--begin::Signin-->
                        <div class="kt-login__form">
                            <div class="kt-login__title">
                                <h3>{{ $t("user_signup") }}</h3>
                                <div class="kt-login__desc">{{ $t("user_signupDesc") }}</div>
                            </div>

                            <!--begin::Form-->
                            <form
                                class="kt-form"
                                action
                                novalidate="novalidate"
                                @submit.prevent="onSignup"
                            >
                                <div class="form-group validated">
                                    <input
                                        class="form-control m-input"
                                        @input="$v.firstname.$touch()"
                                        v-model="firstname"
                                        id="firstNameInput"
                                        type="text"
                                        :placeholder="$t('user_firstname')"
                                        name="firstname"
                                        autocomplete="off"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        v-if="!$v.firstname.required"
                                    >{{ $t("error_fieldIsRequired") }}</div>
                                </div>

                                <div class="form-group validated">
                                    <input
                                        class="form-control m-input"
                                        @input="$v.lastname.$touch()"
                                        v-model="lastname"
                                        id="lastNameInput"
                                        type="text"
                                        :placeholder="$t('user_lastname')"
                                        name="lastname"
                                        autocomplete="off"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        v-if="!$v.lastname.required"
                                    >{{ $t("error_fieldIsRequired") }}</div>
                                </div>

                                <div class="form-group validated">
                                    <input
                                        class="form-control m-input"
                                        @input="$v.email.$touch()"
                                        v-model="email"
                                        id="emailInput"
                                        type="text"
                                        :placeholder="$t('user_email')"
                                        name="email"
                                        autocomplete="off"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        v-if="!$v.email.required"
                                    >{{ $t("error_fieldIsRequired") }}</div>
                                    <div
                                        class="invalid-feedback"
                                        v-if="!$v.email.email"
                                    >{{ $t("error_pleaseEnterValidEmail") }}</div>
                                </div>

                                <div class="form-group validated">
                                    <input
                                        class="form-control m-input m-login__form-input--last"
                                        @input="$v.password.$touch()"
                                        v-model="password"
                                        id="passwordInput"
                                        type="password"
                                        :placeholder="$t('user_password')"
                                        name="password"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        v-if="!$v.password.required"
                                    >{{ $t("error_fieldIsRequired") }}</div>
                                    <div
                                        class="invalid-feedback"
                                        v-if="!$v.password.strongComplexity"
                                    >{{ $t("user_password_rule") }}</div>
                                </div>

                                <div class="form-group validated">
                                    <input
                                        class="form-control m-input m-login__form-input--last"
                                        @input="$v.confirmpassword.$touch()"
                                        v-model="confirmpassword"
                                        id="confirmpasswordInput"
                                        type="password"
                                        :placeholder="$t('user_confirmpassword')"
                                        name="confirmpassword"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        v-if="!$v.confirmpassword.sameAsPassword"
                                    >{{ $t("error_sameaspassword") }}</div>
                                </div>

                                <div class="form-group kt-margin-t-20">
                                    <div class="kt-checkbox-list">
                                        <label
                                            class="kt-checkbox kt-checkbox--bold kt-checkbox--brand"
                                        >
                                            <input
                                                @change="$v.terms.$touch()"
                                                v-model="terms"
                                                type="checkbox"
                                            />
                                            {{ $t("common_haveRead") }}
                                            <a  
                                                v-if="this.releasedFor == 'ALE'"
                                                href="/doc/omniaccess_stellar_at_terms.pdf"
                                                target="_blank"
                                                class="kt-link"
                                                rel="noopener"
                                            >{{ $t("common_termsAndConditions") }}</a>
                                            <a
                                                v-else
                                                href="http://www.han-networks.com/low-u.php"
                                                target="_blank"
                                                class="kt-link"
                                                rel="noopener"
                                            >{{ $t("common_termsAndConditions") }}</a>
                                            <span></span>
                                        </label>
                                    </div>
                                </div>

                                <div
                                    v-if="isCaptchaEnabled"
                                    id="captchaDiv"
                                    class="form-group margintop"
                                >
                                    <vue-recaptcha
                                        v-on:verify="onCaptchaVerfied"
                                        v-on:expired="onCaptchaExpired"
                                        class="captchacenter"
                                        :sitekey="siteKey"
                                        :loadRecaptchaScript="true"
                                    ></vue-recaptcha>
                                </div>

                                <!--begin::Action-->
                                <div class="kt-login__actions">
                                    <router-link to="/">
                                        <a
                                            href="#"
                                            class="kt-link kt-login__link-forgot"
                                        >{{ $t("common_cancel") }}</a>
                                    </router-link>&nbsp;&nbsp;
                                    <button
                                        id="signupButton"
                                        class="btn btn-primary btn-elevate kt-login__btn-primary"
                                        :disabled="$v.$invalid || (isCaptchaEnabled && !captchaVerified)"
                                    >{{ $t("user_signup") }}</button>
                                </div>
                                <!--end::Action-->
                            </form>
                            <!--end::Form-->
                        </div>
                        <!--end::Signin-->
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Content-->
            </div>
        </div>
    </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { required, email, helpers, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import authHelper from "../../helpers/authHelper";

export default {
    data() {
        return {
            releasedFor: process.env.VUE_APP_RELEASED_FOR,
            email: "",
            password: "",
            firstname: "",
            lastname: "",
            terms: false,
            confirmpassword: "",
            siteKey: process.env.VUE_APP_CAPTCHA_SITE_ID,
            isCaptchaEnabled:
                process.env.VUE_APP_CAPTCHA_ENABLED.toLowerCase() == "true"
                    ? true
                    : false,
            captchaVerified: false
        };
    },
    created: function() {
        console.log("Component(Signin)::created() - called");
        if (this.$route.query.inviteeEmail) {
            this.email = this.$route.query.inviteeEmail;
        }
    },
    mounted: function() {
        console.log("Component(Signin)::mounted() - called");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(Signin)::destroyed() - called");
    },
    validations: {
        firstname: {
            required
        },
        lastname: {
            required
        },
        email: {
            required,
            email
        },
        password: {
            required,
            strongComplexity: helpers.regex(
                "alpha",
                /^(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[0-9])(?=.*[a-z]).{8,}$/
            )
        },
        confirmpassword: {
            sameAsPassword: sameAs("password")
        },
        terms: {
            sameAs: sameAs(() => true)
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
    },
    methods: {
        ...mapActions(["signup", "showUserAlreadyLogged"]),

        onSignup() {
            // User already logged in browser (another tab) signup is forbidden
            if (authHelper.isLocalStorageAuthenticated()) {
                this.showUserAlreadyLogged();
                return;
            }

            console.log("Component(Signup)::onSignup() - called");
            const formData = {
                email: this.email,
                firstname: this.firstname,
                lastname: this.lastname,
                password: this.password
            };
            this.signup({
                firstname: formData.firstname,
                lastname: formData.lastname,
                email: formData.email,
                password: formData.password
            });
        },

        onCaptchaVerfied() {
            this.captchaVerified = true;
        },

        onCaptchaExpired() {
            this.captchaVerified = false;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "vue-recaptcha": VueRecaptcha
    }
};
</script>

<style scoped>
.logo_ale {
    width: 170px;
}
.margintop {
    margin-top: 1.6rem !important;
}
.captchacenter {
    margin-left: auto;
    margin-right: auto;
    display: table;
}
</style>
